import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import sampleImage from "../img/products/sample-image.jpg"
import { Link } from "gatsby"

export default function AboutUs() {
  const [productList, setproductList] = useState([
    "USB HUB",
    "PENDRIVE",
    "POWER BANK",
    "HEADPHONES & EARPODS",
    "BLUETOOTH SPEAKER",
    "WIRELESS CHARGER",
    "SMART DEVICES",
  ])
  return (
    <div>
       <Helmet>
        <title>Gadgets - Represent Corporate Giftings</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="Gadgets - Represent Corporate Giftings" />
        <meta
          name="description"
          content="Gadgets - Gift your techies with the latest technology."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://representgiftings.com/bags" />
        <meta
          property="og:description"
          content="Gadgets - Gift your techies with the latest technology."
        />
      </Helmet>
      <Layout>
        <div className="pageHeader">
          <h1>GADGETS</h1>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            {productList.map(product => (
              <div className="col-md-3">
                <div className="productDetailsCard">
                  <img
                    src={
                      "https://representgiftings.com/images/" +
                      product
                        .toLowerCase()
                        .replace("&", "")
                        .replace(" ", "-")
                        .replace(" ", "") +
                      ".jpg"
                    }
                    alt={product}
                  ></img>
                  <h5 className="productDetailstitle">{product}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </div>
  )
}
